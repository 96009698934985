import React, { useContext } from 'react';

import { Alert, Text, ChromaContext } from '../../components/Chroma';
import { useHistory } from 'react-router-dom';
import Container from '@iag-packages/chroma-react/lib/layouts/grid/Container';
import * as brandHelper from '../../lib/api/utils/brandHelper';

const Failure = (props) => {
  const { brand } = useContext(ChromaContext);

  const getPhoneNumber = brand === brandHelper.Brands.lantern ? '0800 800 800' : '0800 807 926';

  const history = useHistory();
  return (
    <Container>
      <Alert error={true} heading="Error" closeButton={true} onClose={() => history.push('/pay')}>
        <Text>
          {`Sorry, payment unsuccessful. Please try again or call us on  ${getPhoneNumber} to make your payment. We apologize
          for any inconvenience.`}
        </Text>
      </Alert>
    </Container>
  );
};

export default Failure;
